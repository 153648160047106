<template>
	<div>
		<div class="footer">
			<div class="footer-herder">
				<div class="footer-flex">
					<div class="aboundus">
						<span>联系我们</span>
						<span></span>
					</div>
					<div class="aboundus">
						<span>工作时间:</span>
						<span class="text">09:00 - 17:30</span>
					</div>
					<div class="aboundus">
						<span>服务热线:</span>
						<span class="text">4006182019</span>
					</div>
					<div class="aboundus">
						<span>QQ售前:</span>
						<span class="text">472720628</span>
					</div>
					<div class="aboundus">
						<span>QQ投诉通道:</span>
						<span class="text">472720628</span>
					</div>
				</div>
				<div class="footer-flex">
					<div class="aboundus">
						<span style="width:90%; font-size: 12px;">拨打专线更快响应哦</span>
						<!-- <span></span> -->
					</div>
					<div class="aboundus">
						<span>渠道合作:</span>
						<span class="text">021-56029889</span>
					</div>
					<div class="aboundus">
						<span>市场推广:</span>
						<span class="text">021-56029889</span>
					</div>
					<div class="aboundus">
						<span>连锁客户:</span>
						<span class="text">021-56029889</span>
					</div>
          <div class="aboundus">
            <span>技术服务:</span>
            <span class="text">15938337182</span>
          </div>
				</div>
				<div class="footer-flex">
					<div class="aboundus">
						<span>资质证照</span>
						<span></span>
					</div>
					<div class="aboundus">
						<span style="cursor: pointer;" @click="$router.push('/certificate')">营业执照</span>
						<span></span>
					</div>
					<div class="aboundus">
						<span style="cursor: pointer;" @click="Abfun('tw')">软著展示</span>
						<span></span>
					</div>

				</div>
				<div class="footer-flex" style="text-align: center;">
					<p style="width: 100%;">
						<icon-svg iconClass='weixin'></icon-svg> 微信咨询
					</p>
					<img style="width: 30%;"
						src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wx.png">
					<p style="width: 100%;">微信扫一扫或者</br>搜索“大总管”</p>
				</div>
			</div>
			<div class="footer_footer">
				<div class="footer_footer_her">
					<div @click="$router.push('/downAPP')">
						版本更新
					</div>
					<div>
						支持社区
					</div>
					<div @click="$router.push('/cooperation')">
						渠道合作
					</div>
					<div @click="KFfun">
						开放平台
					</div>
					<div>
						服务协议
					</div>
					<div @click="abus('on')">
						关于我们
					</div>
					<div style="border: none;" @click="abus('fo')">
						联系我们
					</div>
				</div>
				<div style="text-align: center;margin-top: 2%;font-size: 12px;">
					<p>2021-2021 © Copyright. DaZongGuan - All Rights Reserved</p>
					<p>大总管（上海）科技有限公司版权所有 <a style="color: #9B9B9B; text-decoration:none"
							href="https://beian.miit.gov.cn/#/Integrated/recordQuery">沪ICP备2021022797号-1</a></p>
					<p> <img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/beian.png"> <a
							style="color: #9B9B9B;  text-decoration:none"
							href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006465"
							target="_blank">沪公网安备 31010602006465 号</a></p>
				</div>
			</div>
		</div>
		<div class="phone_footer">
			<div style="text-align: center;font-size: 12px;">
				<p>2021-2021 © Copyright. DaZongGuan - All Rights Reserved</p>
				<p>大总管（上海）科技有限公司版权所有 <a style="color: #9B9B9B; text-decoration:none"
						href="https://beian.miit.gov.cn/#/Integrated/recordQuery">沪ICP备2021022797号-1</a></p>
				<p> <img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/beian.png"> <a
						style="color: #9B9B9B;  text-decoration:none"
						href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006465">沪公网安备
						31010602006465 号</a></p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			KFfun() {
				console.log(13)
				window.open('http://rhdgj.ronghengzn.com/', '_blank');
			},
			abus(val) {
				this.$router.push('/aboutUs')
				this.$store.commit('abtabfun', val)
			},
			Abfun(val) { //关于我们
				this.$router.push('/aboutUs')
				this.$store.commit('abtabfun', val)
			},
		}
	}
</script>

<style scoped="scoped" lang="less">
	@media screen and (max-width: 720px) {
		.footer {
			display: none;
		}
	}

	@media screen and (min-width: 720px) {
		.footer {
			width: 100%;
			height: 450px;
			background-color: #272b2e;
			padding-top: 2%;
			font-size: 14px;

			.footer-herder {
				width: 80%;
				height: 50%;
				margin-left: 10%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #9B9B9B;

				.footer-flex {
					width: 24%;
					height: 100%;
					// background-color: #000088;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;
					color: #FFFFFF;

					.aboundus {
						width: 70%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						align-content: center;

						span {
							display: block;
						}

						.text {
							color: #9b9b9b;
						}
					}
				}
			}

			.footer_footer {
				width: 80%;
				height: 40%;
				margin-left: 10%;
				color: #9b9b9b;
				margin-top: 2%;

				.footer_footer_her {
					width: 80%;
					margin-left: 10%;
					display: flex;
					justify-content: center;

					div {
						width: 10%;
						text-align: center;
						border-right: 1px solid #9B9B9B;
						cursor: pointer;
					}
				}
			}
		}
	}

	@media screen and (max-width: 720px) {
		.phone_footer {
			// display: none;
			background-color: #231f20;
			color: #FFFFFF;
			margin: 0;
		}
	}

	@media screen and (min-width: 720px) {
		.phone_footer {
			display: none;
		}
	}
</style>
